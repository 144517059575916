import { AxiosResponse } from 'axios';
import api from './http';
import { GetSolutionProjectsResponse, Project } from '@type/projects';

export default class ProjectsService {
  static async getSolutionProjects(
    partnerId: number,
    params: string = '',
    nextPage?: string,
    prev?: string
  ): Promise<AxiosResponse<GetSolutionProjectsResponse>> {
    if (nextPage) {
      return api.get<GetSolutionProjectsResponse>(nextPage);
    }
    if (prev) {
      return api.get<GetSolutionProjectsResponse>(prev);
    }
    const paramsData = params ? `?${params}` : '';
    return api.get<GetSolutionProjectsResponse>(
      `/api/v1/partners/${partnerId}/projects${paramsData}`
    );
  }

  static async getProject(id: number) {
    return api.get<Project>(`/api/v1/partners/project/${id}`);
  }
}

import clsx from 'clsx';
import { Button } from 'components/shared/Button';
import FormatPrice from 'components/shared/FormatPriceAndCurrency';
import { CardIcon } from 'components/shared/Icons/CardIcon';
import { HeartIcon } from 'components/shared/Icons/HeartIcon';
import { PurpleTimeIcon } from 'components/shared/Icons/PurpleTimeIcon';
import PriceText from 'components/shared/PriceText';
import { useWindowWidth } from 'hooks/useWindowWidth';
import i18n from 'i18n/i18n';
import { observer } from 'mobx-react-lite';
import classes from './ServiceInfo.module.scss';
import { ServiceInfoProps } from './ServiceInfo.props';
import { useServiceInfo } from './useServiceInfo';
import { PaymentType } from 'types/partner';
import { getLangName } from 'tools/utils';
import { useCommonStore } from 'contexts/CommonContext';
import { RUSSIAN_LOCALE } from 'utils';

export const ServiceInfo = observer(({ data }: ServiceInfoProps) => {
  const { t } = useServiceInfo(data);
  const { width } = useWindowWidth();
  const { products } = useCommonStore();

  const formattedPrice = (price?: number | string, withMinus?: boolean) => {
    if (price) {
      if (typeof price === 'string') {
        price = Number(price.replace(/\s/g, ''));
      }

      return (
        <FormatPrice
          currency={RUSSIAN_LOCALE ? '₽' : '$'}
          price={price}
          withMinus={withMinus}
        />
      );
    } else {
      return 0;
    }
  };

  const product = products.find((productType) => {
    return productType.id === data.productType;
  });

  const displayTags = data?.is_free || data?.is_new || product?.name;

  return (
    <div className={classes.wrapper}>
      {displayTags && (
        <div className={classes.tags}>
          {data?.is_new && (
            <div className={clsx(classes.tag, classes.new)}>{t('NEW')}</div>
          )}
          {data?.is_free && (
            <div className={clsx(classes.tag, classes.free)}>{t('FREE')}</div>
          )}
          <div className={classes.tag}>{product?.name}</div>
        </div>
      )}

      <div className={classes.title}>{getLangName(data, 'name', i18n)}</div>

      <div className={classes['payment-info']}>
        <div className={classes.block}>
          <div className={classes.icon}>
            <CardIcon />
          </div>

          <div className={classes.info}>
            <div className={classes['info-title']}>{t('Payment')}</div>
            <div className={classes['info-text']}>
              {data?.payment_type &&
                data?.payment_type?.map((item: any, index: number) => (
                  <span key={index} className={classes.infoName}>
                    {getLangName(item, 'name', i18n) || item?.label}
                  </span>
                ))}
            </div>
          </div>
        </div>

        <div className={classes.block}>
          <div className={clsx(classes.icon, classes.timer)}>
            <PurpleTimeIcon width={20} height={20} />
          </div>

          <div className={classes.info}>
            <div className={classes['info-title']}>{t('Duration')}</div>
            <div className={classes['info-text']}>
              {getLangName(data, 'projectDuration', i18n)}
            </div>
          </div>
        </div>
      </div>

      {data?.shortDescription && data.shortDescription !== '' && (
        <div className={classes.description}>
          <div className={classes['description-title']}>{t('Description')}</div>
          <div className={classes['description-text']}>
            <div
              dangerouslySetInnerHTML={{
                __html: getLangName(data, 'shortDescription', i18n),
              }}
            />
          </div>
        </div>
      )}

      {data?.worksFor && data.worksFor !== '' && (
        <div className={classes.description}>
          <div className={classes['description-title']}>{t('Works for')}</div>
          <div className={classes['description-text']}>
            <div
              dangerouslySetInnerHTML={{
                __html: getLangName(data, 'worksFor', i18n),
              }}
            />
          </div>
        </div>
      )}

      {width < 961 && (
        <div className={classes['mobile-price-block']}>
          <div className={classes.price}>
            <div className={classes.text}>
              <PriceText data={data} t={t} asteriskSymbol />
            </div>

            {data?.is_free ? (
              <div className={classes.cost}>{t('Free')}</div>
            ) : (
              <div className={clsx(classes.cost, classes['cost'])}>
                {formattedPrice(data?.price, false) || 0}
              </div>
            )}
          </div>

          <div className={classes.buttons}>
            <Button className={classes.buttons_addToCartBtn} theme="primary">
              {t('Add to cart')}
            </Button>

            <div className={classes.favored}>
              <Button className={classes.favorite} theme="icon-default">
                <HeartIcon fill={'#8362F3'} />
              </Button>
            </div>
          </div>

          {!data?.is_free && (
            <div className={classes.priceDesciption}>
              *{' '}
              {t(
                "This price is\u00A0preliminary. VAT is\u00A0not included. You'll get VAT and the total price calculation and terms of\u00A0deal after consultation with our sales manager. To\u00A0know more about the price please check our "
              )}{' '}
              <a href="/pricing-disclaimer/ " className={classes.disclaimer}>
                {t('Pricing Disclaimer')}
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  );
});

import React, { useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';
import * as yup from 'yup';
import clsx from 'clsx';
import { Link } from '../../components/shared/Link';
import { Input } from 'components/shared/Input';
import { Button } from 'components/shared/Button';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { InfoIcon } from 'components/shared/Icons/InfoIcon';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import logo from 'assets/img/logo.png';
import desktop from 'assets/img/Restore/desktop.png';
import { useWindowWidth } from 'hooks/useWindowWidth';
import classes from './Restore.module.scss';
import { Request } from 'tools/request';
import { LeftArrowIcon } from 'components/shared/Icons/LeftArrowIcon';
import { SuccessIcon } from 'components/shared/Icons/SuccessIcon';
import { toNbsp } from 'tools/toNbsp';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { REGEX_EMAIL_254 } from 'constants/matches';
import { OpenModalType } from './types';
import { PLATFORM } from './constants';
import RestoreModal from './RestoreModal';
import { languages } from 'constants/languages';
import { MultiselectComponent } from 'components/shared/MultiselectComponent';
import { Language } from '@type/common';

export interface FormValues {
  email: string;
  recaptcha?: string;
}

export const RestoreContainer = () => {
  const { t, i18n } = useTranslation();
  const isDisableCaptcha = process.env.REACT_APP_DISABLE_RECAPCHA === 'true';

  const restoreValidationSchema = yup.object().shape({
    email: yup
      .string()
      .matches(REGEX_EMAIL_254, t('Invalid Email'))
      .required(t('This field is required')),
    recaptcha: isDisableCaptcha
      ? yup.string()
      : yup.string().required(t('This field is required')),
  });
  const {
    getValues,
    control,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(restoreValidationSchema as any),
    mode: 'all',
  });
  const [step, setStep] = useState(0);
  const [openModal, setOpenModal] = useState<OpenModalType>({
    desktop: false,
    mobile: false,
  });
  const [resendRecaptcha, setResendRecaptcha] = useState('');

  const { DESKTOP, MOBILE } = PLATFORM;
  const { isMediaTablet } = useWindowWidth();

  const { email, recaptcha } = getValues();

  const submit = useCallback(async () => {
    const formData = new FormData();

    formData.append('email', email);
    if (!isDisableCaptcha && recaptcha)
      formData.append(
        'g-recaptcha-response',
        step === 0 ? recaptcha : resendRecaptcha
      );

    await Request({
      type: 'POST',
      url: `${process.env.REACT_APP_API}/api/v1/auth/restore/`,
      data: formData,
    })
      .then(() => {
        setStep(1);
      })
      .catch((error) => {
        if (error?.response?.data) {
          setError('email', {
            type: 'server',
            message: error.response.data?.email[0],
          });
        }
      });
  }, [email, recaptcha, resendRecaptcha, setError, step]);

  const changeLanguage = (langObj: Language) => {
    i18n.changeLanguage(langObj.lang_code);
    localStorage.setItem('kitGlobalLng', langObj.lang_code);
  };

  const handleResend = async () => {
    await submit();
  };

  const handleOpenModal = (platform: string) => {
    if (platform === DESKTOP) {
      setOpenModal({ ...openModal, desktop: true });
    } else if (platform === MOBILE) {
      setOpenModal({ ...openModal, mobile: true });
    }
  };

  return (
    <>
      <TitleAndDescription pageName="Restore" />
      <div className={clsx(classes.container, 'restore-container')}>
        <NavLink to={`${process.env.REACT_APP_MAIN_SITE_URL}`}>
          <img src={logo} alt="" className={classes.logo} />
        </NavLink>
        <div className={classes.left}>
          <img src={desktop} alt="" className={classes.image} />
        </div>
        <div className={classes.right}>
          <div className={classes.lang}>
            <MultiselectComponent
              data={languages}
              langSelect={true}
              defaultSelected={
                languages[
                  languages.findIndex(
                    (lang) => lang.lang_code === i18n.language
                  )
                ]?.id
              }
              setMultiselect={changeLanguage}
              className={classes['multiselect-lang']}
            />
          </div>
          {step === 0 && (
            <>
              <Link className={classes['return-button']} href="/login">
                <LeftArrowIcon />
                {t('Back')}
              </Link>
              <div className={classes.title}>{t('Forgot password')}?</div>
              <div className={classes.text}>
                {t('Enter your email to restore password')}
              </div>
              <form className={classes.form} onSubmit={handleSubmit(submit)}>
                <div className={classes.fields}>
                  <div className={classes['input-field']}>
                    <div className={classes['item-label']}>
                      {t('Work email')}
                    </div>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Input
                          type={'text'}
                          placeholder={t('Enter your work email')}
                          value={field.value}
                          error={!!errors?.email}
                          onChange={field.onChange}
                        />
                      )}
                      name="email"
                    />
                    {errors?.email && (
                      <TooltipContainer
                        text={errors.email?.message}
                        customClasses={'kit-ui-block'}
                        position={'top'}
                        className={classes.error}
                      >
                        <InfoIcon fill="#F5222D" width="18px" height="18px" />
                      </TooltipContainer>
                    )}
                  </div>
                </div>
                {isDisableCaptcha ? null : (
                  <div className={classes.captcha}>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <ReCAPTCHA
                          sitekey={`${process.env.REACT_APP_GOOGLE_RECAPTHA_KEY}`}
                          onChange={field.onChange}
                          hl="en"
                          size="normal"
                        />
                      )}
                      name="recaptcha"
                    />
                  </div>
                )}
                <div className={classes.buttons}>
                  <Button
                    className={clsx(classes.button, classes['submit-button'])}
                    theme="primary"
                    disabled={!isValid}
                  >
                    {t('Restore')}
                  </Button>
                </div>
              </form>
            </>
          )}
          {step === 1 && (
            <>
              <div className={classes.heading}>
                <SuccessIcon />
                {toNbsp(t('Restore password link sent'))}
              </div>
              <div className={classes.body}>
                {!isMediaTablet && (
                  <div className={classes.text}>
                    {t(
                      'We’ve sent you a link for restoring password. Please check '
                    )}
                    <div className={classes.email}>
                      {t('your email ')}
                      <span>{email}</span>
                    </div>
                  </div>
                )}
                {isMediaTablet && (
                  <div className={classes.text}>
                    {t(
                      'We’ve sent you a link for restoring password. Please check your email'
                    )}
                    <div className={classes.email}>
                      <span>{email}</span>
                    </div>
                  </div>
                )}
                {!isMediaTablet && (
                  <div className={classes.help}>
                    {t('Can’t find it? Check your spam folder or')}
                    <span
                      className={classes.link}
                      onClick={() => handleOpenModal(DESKTOP)}
                    >
                      {t('resend a link')}
                    </span>
                    .
                  </div>
                )}
                {isMediaTablet && (
                  <div className={classes.help}>
                    <div>{t('Can’t find it?')}</div>
                    {t('Check your spam folder or')}
                    <span
                      className={classes.link}
                      onClick={() => handleOpenModal(MOBILE)}
                    >
                      {t('resend a link')}
                    </span>
                    .
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <RestoreModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        title={t('Resend verification email')}
        t={t}
        description={t(
          'To resend the verification email, please complete the CAPTCHA below for security purposes'
        )}
        setRecaptcha={setResendRecaptcha}
        handleRestore={handleResend}
      />
    </>
  );
};

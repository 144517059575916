export const onlyLetters = /^[A-Za-zА-Яа-яЁё\s-]+$/;
export const numbersCheckReg = /\d+/;
export const symbolsCheckReg = /[~!@#$%^&*()_+<>?￥¿¡·॰।]/;
export const urlCheckReg =
  /^(http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,256}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g;
export const noSpacesCheckReg = /^[^\s]*$/;
export const lettersCheckReg = /[A-Za-z]/;
export const REGEX_EMAIL_254 =
  /^[a-zA-Z0-9._%+-]{1,126}@[a-zA-Z0-9.-]{1,63}.[a-zA-Z]{2,63}$/;
export const htmlTagPattern = /<\/?[a-z][\s\S]*>/i;

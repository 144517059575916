import { toast, ToastOptions } from 'react-toastify';
import { Toast } from 'components/shared/Toast';
import clsx from 'clsx';

interface Props {
  type: 'success' | 'warning' | 'error';
  children?: string;
  toastProps?: ToastOptions;
  title?: string;
  isNewToast?: boolean;
}

export function ShowNotification({
  type,
  children,
  toastProps,
  title,
  isNewToast,
}: Props) {
  return toast(
    <Toast type={type} title={title} text={children} isNewToast={isNewToast} />,
    {
      ...toastProps,
      className: clsx(
        'toast-container-big',
        isNewToast && 'toast-container_new'
      ),
    }
  );
}

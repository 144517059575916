import { useAuthStore } from 'contexts/AuthContext';
import { useUserStore } from 'contexts/UserContext';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ShowNotification } from 'tools/showNotification';
import { setAccessToken, setRefreshToken } from 'services/http';
import { Language } from '@type/common';
import { languages } from 'constants/languages';
import { useSearchParams } from 'react-router-dom';

export const useRegistration = () => {
  const [step, setStep] = useState(1);
  const [sendEmail, setSendEmail] = useState('');
  const [successData, setSuccessData] = useState<any>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const authStore = useAuthStore();
  const userStore = useUserStore();

  const { i18n, t } = useTranslation();

  const resendEmail = async () => {
    const result = await authStore.loginByTokens(
      successData?.access,
      successData?.refresh
    );

    if (result) {
      const user = await userStore.loadUser();

      if (!user?.email_confirmed) {
        await userStore.sendRegistrationCompleteEmail();
        ShowNotification({
          type: 'success',
          children: t(
            'Email successfully sent. It may take up to 2 minutes to deliver'
          ),
        });
      } else {
        ShowNotification({
          type: 'error',
          children: t('Your email is already confirmed'),
        });
      }
      setAccessToken('');
      setRefreshToken('');
      authStore.setAuth(false);

      return;
    } else {
      ShowNotification({
        type: 'error',
        children: t('Something went wrong, please try later'),
      });
    }
  };

  const changeLanguage = (langObj: Language) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (newSearchParams.has('lng')) {
      newSearchParams.delete('lng');
      setSearchParams(newSearchParams);
    }
    i18n.changeLanguage(langObj.lang_code);
    localStorage.setItem('kitGlobalLng', langObj.lang_code);
  };

  const defaultLanguage =
    languages[languages.findIndex((lang) => lang.lang_code === i18n.language)]
      ?.id;

  return {
    step,
    setStep,
    t,
    setSendEmail,
    sendEmail,
    setSuccessData,
    resendEmail,
    changeLanguage,
    defaultLanguage,
  };
};

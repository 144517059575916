import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import classes from './Employees.module.scss';
import { Type } from 'components/shared/Type';
import { useTranslation } from 'react-i18next';
import EmployeesInvite from './EmployeesInvite/EmployeesInvite';
import { tableDataEmployees } from './employeesTableData';
import { observer } from 'mobx-react-lite';
import TableEmployees from './TableEmployees';
import { useEmployees } from './useEmployees';
import { PARTNER_ROLE } from 'types/user';
import { RUSSIAN_LOCALE } from 'utils';
import { partnerStore } from 'stores/partnerStore';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { DISABLE_TRANSLATION } from 'constants/languages';

const Employees = observer(() => {
  const { t } = useTranslation();
  const {
    partner,
    isLoading,
    clients,
    columns,
    getUsers,
    handleDeletePermissions,
    handleChangePermissions,
  } = useEmployees();

  const partnerPermission = partner?.permission?.type;

  const isPartnerAdmin =
    partnerPermission === PARTNER_ROLE.ADMIN && partnerStore.allowInvite;

  const { isMediaTablet } = useWindowWidth();

  const descriptionForAdmin = isMediaTablet
    ? t(
        'Inviting employees to collaborate on the platform and managing access levels'
      )
    : t(
        'Inviting employees to collaborate on the platform and managing access levels'
      );

  return (
    <div className={classes.container}>
      <TitleAndDescription pageName="Account/employees" />

      <div className={classes.wrapper} translate={DISABLE_TRANSLATION}>
        <div className={classes.titleWrap}>
          <Type tag={'h2'} className={classes.title}>
            {t('Employees')}
          </Type>
        </div>
        <p className={classes['info']}>
          {isPartnerAdmin ? (
            descriptionForAdmin
          ) : (
            <>
              {t('View employee invitations and check access levels')}.
              <br />
              {t('Inviting new employees is not available for your role')}
            </>
          )}
        </p>

        {isPartnerAdmin && (
          <EmployeesInvite partner={partner} getUsers={getUsers} />
        )}

        {isLoading || !clients ? (
          <div>{t('Loading...')}</div>
        ) : (
          <TableEmployees
            additionalClassNames={classes.table}
            data={tableDataEmployees(clients, t)}
            columns={columns}
            partner={partner}
            handleDeletePermissions={handleDeletePermissions}
            handleChangePermissions={handleChangePermissions}
          />
        )}
      </div>
    </div>
  );
});

export default Employees;

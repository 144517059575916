import { useUserStore } from 'contexts/UserContext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RUSSIAN_LOCALE } from 'utils';
import { getLangName } from 'tools/utils';
import { toJS } from 'mobx';

export const usePreview = (data: any, isDisabled?: boolean) => {
  const userStore = useUserStore();
  const [hasError, setHasError] = useState(false);
  const { t, i18n } = useTranslation();

  const defaultCase = () => {
    if (data?.cases?.length) {
      return data?.cases?.map((item: any) => ({
        caseName: item?.name,
        caseBusinessType: item?.business_type?.id,
        keyAchievements: getLangName(item, 'achievement', i18n),
        platforms: item?.platforms,
        secondStepDescription: getLangName(item, 'description', i18n),
        kpi: item?.case_kpises?.map((kpi: any, index: number) => ({
          title: `KPI ${index + 1}`,
          step: index + 1,
          name: kpi?.description,
          value: kpi?.amount,
          valueType: kpi?.amount_type,
          unit: kpi?.amount_unit,
        })),
      }));
    } else return [];
  };

  const serviceInfo = isDisabled
    ? {
        name: data?.name || '',
        worksFor: data?.worksFor || '',
        companySize: data?.company_size,
        industry: data?.business_type,
        goals: data?.goals,
        shortDescription: data?.short_description || '',
        serviceTypes: data?.service_type,
        fullDescription: data?.full_description || '',
        productType: data?.product?.id || 0,
        price: data?.cost || 0,
        projectDuration: data?.period_comment,
        cases: defaultCase(),
        currency: RUSSIAN_LOCALE ? 'Российский рубль, ₽' : 'USD',
        'useCases-2': {
          name: data?.files?.[0]?.name || '',
          size: data?.files?.[0]?.size || 0,
          id: data?.files?.[0]?.id,
        },
        is_free: data?.is_free || false,
        countries: data?.countries,
        payment_type: data?.payment_types,
      }
    : data;

  useEffect(() => {
    if (!isDisabled) {
      window?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  const [isHotDeal, setIsHotDeal] = useState(false);

  const highlightCartClass = document.querySelector(
    '.tooltip-cart'
  ) as HTMLElement;

  useEffect(() => {
    if (highlightCartClass) {
      highlightCartClass.style.padding = '5px';
    }
  }, [highlightCartClass]);

  return {
    serviceInfo,
    hasError,
    t,
    i18n,
    userStore,
    isHotDeal,
  };
};

import { SelectData } from 'components/shared/SelectComponent';
import { htmlTagPattern } from 'constants/matches';
import { useUserStore } from 'contexts/UserContext';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { MutableRefObject, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import formClasses from '../FormBlock/FormBlock.module.scss';
import { onDelete, onSelect } from '../utils';
import { FormValidationProps } from '../FormBlock/FormBlock.props';
import { MultiSelectProps } from '../FormBlock/FirstStep/FirsStep.props';
import { MultiSelectOption } from 'components/shared/MultiSelect';
import { BusinessType } from '@type/common';
import { usePartnerStore } from 'contexts/PartnerContext';

export const useAddCaseForm = (
  caseNumber?: number,
  disabled?: boolean,
  platforms?: any,
  businessTypes?: BusinessType[],
  currentStep?: number,
  fieldRefs?: MutableRefObject<Record<string, HTMLDivElement | null>>
) => {
  const {
    control,
    formState: { errors },
    clearErrors,
  } = useFormContext<FormValidationProps>();
  const { t, i18n } = useTranslation();
  const userStore = useUserStore();
  const { isCompletedFormSteps } = usePartnerStore();
  const { isMediaTablet, isSmallLaptop: isLaptop } = useWindowWidth();

  const caseNameValue = useWatch({ name: `cases.${caseNumber}.caseName` });
  const caseBusinessTypeValue = useWatch({
    name: `cases.${caseNumber}.caseBusinessType`,
  });
  const platformsValue = useWatch({ name: `cases.${caseNumber}.platforms` });
  const businessTypesValue = useWatch({
    name: `cases.${caseNumber}.businessTypes`,
  });

  const [generalCount, setGeneralCount] = useState<number>(0);
  const [selectBusinessType, setSelectBusinessTypes] =
    useState<SelectData | null>(null);
  const [selectPlatforms, setSelectPlatforms] = useState<MultiSelectProps[]>(
    platformsValue || []
  );
  const [internalPlatforms, setInternalPlatforms] = useState<
    MultiSelectOption[]
  >([]);

  const [internalBusinessTypes, setInternalBusinessTypes] = useState<
    SelectData[]
  >(businessTypesValue || []);

  useEffect(() => {
    setGeneralCount(
      [caseNameValue, caseBusinessTypeValue, platformsValue?.length].filter(
        (item: any) => item
      ).length
    );
  }, [caseNameValue, caseBusinessTypeValue, platformsValue]);

  useEffect(() => {
    platforms && setInternalPlatforms(platforms);
  }, [platforms]);

  useEffect(() => {
    businessTypes && setInternalBusinessTypes(businessTypes);
  }, [businessTypes]);

  const formattedValue = (value: string) =>
    value && htmlTagPattern.test(value)
      ? value?.replace(/<\/?[^>]+>/gi, '')
      : value;

  const callbacks = {
    onSelectProduct: (id: number | string, onChange: any) =>
      onSelect(id, platforms, setSelectPlatforms, onChange),
    onDeleteSelectedProduct: (id: number | string, onChange: any) =>
      onDelete(id, setSelectPlatforms, onChange),
  };

  const endIconOptions = (title: string) => ({
    endIconName: 'HelpIcon',
    endIconTooltipText: title,
    endIconTooltipPosition: isMediaTablet ? 'top' : 'right',
    endIconTooltipClassName: formClasses.endIconTooltip,
    endIconClassName: formClasses.endIcon,
  });

  const findPath = (obj: any, keyToFind: any) => {
    function search(obj: any, path: any) {
      if (obj && typeof obj === 'object') {
        for (const key in obj) {
          const newPath = path ? `${path}.${key}` : key;
          if (key === keyToFind) return newPath;
          const result: any = search(obj[key], newPath);
          if (result) return result;
        }
      }
      return null;
    }
    return search(obj, '');
  };

  useEffect(() => {
    if (currentStep === 2) {
      const errorsKeys = Object.keys(errors?.cases?.[caseNumber || 0] || {});
      const errorElementPath = findPath(errors, errorsKeys?.[0]);
      if (errorsKeys?.length > 0) {
        fieldRefs?.current?.[errorElementPath]?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        isCompletedFormSteps.stepTwo = false;
      } else {
        isCompletedFormSteps.stepTwo = true;
      }
    }
  }, [isCompletedFormSteps.stepTwo, fieldRefs, errors]);

  return {
    isLaptop,
    t,
    i18n,
    generalCount,
    endIconOptions,
    errors,
    control,
    setSelectBusinessTypes,
    selectPlatforms,
    callbacks,
    formattedValue,
    internalPlatforms,
    internalBusinessTypes,
    setInternalBusinessTypes,
    clearErrors,
  };
};

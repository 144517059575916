import { Notice } from 'components/shared//Notice';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { useRef } from 'react';
import { Controller } from 'react-hook-form';
import classes from './Business.module.scss';
import { BusinessProps } from './Business.props';
import { useBusiness } from './useBusiness';
import BusinessHeader from './BusinessHeader/BusinessHeader';
import { Select } from 'components/shared/Select';
import NewInput from 'components/shared/NewInput';
import { SelectData } from 'components/shared/SelectComponent';
import clsx from 'clsx';
import MultiSelect from 'components/shared/MultiSelect';
import { multiFieldValues } from '../../AddSolution/utils';
import BusinessFooter from './BusinessFooter/BusinessFooter';
import { PARTNER_ROLE } from 'types/user';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { DISABLE_TRANSLATION } from 'constants/languages';

export const Business = ({
  defaultValue,
  getParams,
  disabled,
}: BusinessProps) => {
  const formRef = useRef(null);
  const {
    t,
    handleSubmit,
    submit,
    errors,
    control,
    setCompanyLink,
    setSelectedCompanyLocation,
    notice,
    callbacks,
    internalProducts,
    setInternalProducts,
    selectProducts,
    productsOptions,
    isValid,
    disableSubmit,
    handleCopy,
    partner,
    countries,
    i18n,
  } = useBusiness(defaultValue, getParams);

  const { isMediaTablet } = useWindowWidth();

  return (
    <>
      <TitleAndDescription pageName="Account/business" />

      <div className={clsx(classes.wrapper)} translate={DISABLE_TRANSLATION}>
        <BusinessHeader partner={partner} />

        <form
          className={classes.inner}
          ref={formRef}
          onSubmit={handleSubmit(submit)}
        >
          <div className={classes.box}>
            <Controller
              name="companyName"
              control={control}
              render={({ field }) => (
                <NewInput
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  isAccount
                  title={t('Company Name for Account')}
                  type={'text'}
                  placeholder={t('Company Name for Account')}
                  value={field.value}
                  disabled={disabled}
                  error={!!errors?.companyName}
                  classNames={classes.oneInput}
                />
              )}
            />
            {/*{errors?.companyName && (
              <TooltipContainer
                // text={errors.companyName?.message}
                customClasses={'kit-ui-block'}
                position={'top'}
                className={classes.error}
              >
                <InfoIcon fill="#F5222D" width="18px" height="18px" />
              </TooltipContainer>
            )}*/}

            <div className={classes['two-inputs']}>
              <Controller
                name="companyId"
                control={control}
                render={({ field }) => (
                  <NewInput
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    isAccount
                    classNames={classes.oneInput}
                    type={'text'}
                    title={t('Company ID')}
                    placeholder={t('Company ID')}
                    value={field.value || ''}
                    disabled={disabled}
                    error={!!errors?.companyId}
                    endIcon={{
                      endIconName: 'HelpIcon',
                      endIconTooltipPosition: isMediaTablet ? 'left' : 'top',
                      endIconTooltipText: t(
                        'Helps uniquely recognize your company, reducing data handling errors and avoiding confusion with other entities'
                      ),
                      endIconTooltipClassName: classes.endIconTooltip,
                      endIconClassName: classes.endIcon,
                      tooltipInner: classes.tooltipInner,
                    }}
                  />
                )}
              />
              {/*{errors?.companyName && (
                <TooltipContainer
                  // text={errors.companyName?.message}
                  customClasses={'kit-ui-block'}
                  position={'top'}
                  className={classes.error}
                >
                  <InfoIcon fill="#F5222D" width="18px" height="18px" />
                </TooltipContainer>
              )}*/}

              <Controller
                name="companyLink"
                control={control}
                render={({ field }) => (
                  <NewInput
                    onChange={(e) => {
                      setCompanyLink(e);
                      field.onChange(e);
                    }}
                    isAccount
                    classNames={classes.oneInput}
                    type={'text'}
                    title={t('Company website')}
                    placeholder={t('Company website')}
                    value={field.value}
                    disabled={disabled}
                    error={!!errors?.companyLink}
                    errorText={errors?.companyLink?.message}
                  />
                )}
              />
              {/*{!!errors?.url?.message && (
                <TooltipContainer
                  text={errors.url?.message as string}
                  customClasses={'kit-ui-block'}
                  position={'top'}
                  className={classes.error}
                >
                  <InfoIcon fill="#F5222D" width="18px" height="18px" />
                </TooltipContainer>
              )}*/}
            </div>

            <Controller
              name="companyLocation"
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    select={field.value}
                    isAccountForm
                    className={classes.selectSize}
                    title={t('Office location')}
                    placeholder={t('Select office location')}
                    options={(countries as SelectData[]) || []}
                    onSelect={(item) => {
                      field.onChange(item);
                      setSelectedCompanyLocation(item);
                    }}
                    disabled={disabled}
                    endIcon={{
                      endIconName: 'HelpIcon',
                      endIconTooltipPosition: isMediaTablet ? 'left' : 'top',
                      endIconTooltipText: t(
                        'Helps in categorizing your company for tailored services and offers '
                      ),
                      endIconTooltipClassName: classes.endIconTooltip,
                      endIconClassName: classes.endIcon,
                      tooltipInner: classes.tooltipInner,
                    }}
                  />
                );
              }}
            />

            <Controller
              name="companyProducts"
              control={control}
              render={({ field }) => {
                return (
                  <MultiSelect
                    additionalClassName={classes.selectSize}
                    title={t('Company products for Account')}
                    placeholder=""
                    isAccountForm
                    contentHeight="50px"
                    options={internalProducts}
                    isSearch
                    isSolutionForm
                    showCounter
                    searchPlaceholder={t('Search')}
                    selected={multiFieldValues(
                      selectProducts,
                      field,
                      i18n,
                      true
                    )}
                    onSelect={(id) => {
                      callbacks.onSelectProducts(id, field.onChange);
                    }}
                    onDeleteSelected={(id) => {
                      if (id === 'all') field.onChange(null);
                      callbacks.onDeleteProducts(id, field.onChange);
                      selectProducts.length === 1 && field.onChange(null);
                    }}
                    onSearch={(value) => {
                      productsOptions &&
                        setInternalProducts(
                          productsOptions?.filter((item: any) =>
                            String(item?.label)
                              ?.toLowerCase()
                              ?.includes(value.toLowerCase())
                          )
                        );
                    }}
                    disabled={disabled}
                    endIcon={{
                      endIconName: 'HelpIcon',
                      endIconTooltipPosition: isMediaTablet ? 'left' : 'top',
                      endIconTooltipText: t(
                        "Ensures that communications, offers, and services are relevant to your company's field"
                      ),
                      endIconTooltipClassName: classes.endIconTooltip,
                      endIconClassName: classes.endIcon,
                      tooltipInner: classes.tooltipInner,
                    }}
                  />
                );
              }}
            />
          </div>

          {/*{!RUSSIAN_LOCALE && (
            <div className={classes.submit}>
              <Button
                className={classes.button}
                theme="primary"
                disabled={!disableSubmit || !isValid}
                size="middle"
                type="submit"
              >
                {t('Save changes')}
              </Button>
            </div>
          )}*/}
        </form>

        {partner?.legal_entities?.length &&
        partner.permission?.type === PARTNER_ROLE.ADMIN ? (
          <BusinessFooter partner={partner} handleCopy={handleCopy} />
        ) : null}

        {notice.type !== '' && (
          <div className={classes.notice}>
            <Notice type={notice.type}>{notice.text}</Notice>
          </div>
        )}
      </div>
    </>
  );
};

import { Logo } from 'components/common/Logo';
import { User } from 'components/common/User';
import { Button } from 'components/shared/Button';
import { CrossIcon } from 'components/shared/Icons/CrossIcon';
import { MenuGradientIcon } from 'components/shared/Icons/MenuGradientIcon';
import { LinksPolitic } from 'components/shared/LinksPolitic';
import { Menu } from 'components/shared/Menu';
import { Modal } from 'components/shared/Modal';
import { languages } from 'constants/languages';
import { NavLink } from 'react-router-dom';
import classes from './Header.module.scss';
import { LocalPreferencesForm } from './LocalPreferences';
import clsx from 'clsx';
import React from 'react';
import { AdminGradientIconActive } from 'components/shared/Icons/AdminGradientIconActive';
import { SearchGradientIcon } from 'components/shared/Icons/SearchGradientIcon';
import { SearchComponent } from 'components/shared/SearchComponent';
import { observer } from 'mobx-react-lite';
import { HeaderProps } from './Header.props';
import { useHeader } from './useHeader';
import HeaderButtons from './HeaderButtons/HeaderButtons';
import { authStore } from 'stores/authStore';
import { GlobusGradientIcon } from 'components/shared/Icons/GlobusGradientIcon';
import { RUSSIAN_LOCALE } from 'utils';
import { ModalMobile } from 'components/shared/ModalMobile';
// import { ModalMobile } from 'components/ModalMobile';
import { LocalPreferencesFormMobile } from './LocalPreferences/LocalPreferencesFormMobile';

export const Header = observer(
  ({ disabled = false, allowLogout }: HeaderProps) => {
    const {
      width,
      active,
      bottomMenuSize,
      disableHeader,
      t,
      isSuccessLocalPreferences,
      isActiveLink,
      path,
      userName,
      openSearch,
      setOpenSearch,
      closeMenu,
      openMenu,
      isDisabledModerate,
      isManager,
      isAdmin,
      resetCookies,
      isTestUser,
      isDisabledLocalPreferences,
      handleSendLocalPreferences,
      handleCloseLocalPreferencesModal,
      handleOpenLocalPreferencesModal,
      isOpenLocalPreferencesModal,
      isMediaTablet,
      i18n,
      // userCountry,
      // currencies,
      // countries,
      // userCurrency,
      disableUserLogout,
      currentYear,
    } = useHeader();

    return (
      <>
        <header
          className={clsx(classes['header'], active && classes['active'])}
          id="global-header-id"
        >
          <div
            className={clsx(
              classes['header__inner'],
              authStore.isServerError && classes.disabled
            )}
          >
            <Logo />
            <div className={classes['header__activation']}>
              <div
                className={classes['header__search-icon']}
                onClick={() => !disableHeader && setOpenSearch(!openSearch)}
              >
                <SearchGradientIcon />
              </div>

              <div className={clsx(classes['header__burger'])}>
                {!active ? (
                  <Button
                    className={clsx(
                      disabled && !allowLogout && classes.disabled
                    )}
                    onClick={openMenu}
                  >
                    <MenuGradientIcon />
                  </Button>
                ) : (
                  <Button onClick={closeMenu}>
                    <CrossIcon
                      width={'15px'}
                      height={'15px'}
                      fill={'#252529'}
                    />
                  </Button>
                )}
              </div>
            </div>
          </div>

          <nav className={classes['header__nav']}>
            <Menu
              active={active}
              disabled={disableHeader}
              onModerate={isDisabledModerate}
              closeMenu={closeMenu}
            />
            <div className={classes['header__block']}>
              <div className={classes['header__search']}>
                {(openSearch || width > 1024) && (
                  <SearchComponent
                    disabled={disableHeader}
                    setOpenSearch={setOpenSearch}
                  />
                )}
              </div>

              <ul className={classes['header__list']}>
                {!RUSSIAN_LOCALE && (
                  <li
                    className={clsx(
                      classes['header__list-item'],
                      classes['header__globus']
                      // isDisableHeader && classes.disabled
                    )}
                  >
                    <div
                      className={clsx(classes['header__list-link'])}
                      onClick={handleOpenLocalPreferencesModal}
                    >
                      <GlobusGradientIcon />
                    </div>
                  </li>
                )}

                <li
                  className={clsx(
                    classes['header__list-item'],
                    classes['header__user']
                  )}
                >
                  <NavLink
                    to={disableUserLogout ? '#' : path}
                    className={clsx(
                      classes['header__list-link'],
                      isActiveLink && !disableUserLogout && classes.active,
                      authStore.isServerError && classes.disabled
                    )}
                    onClick={(e) => {
                      disableUserLogout && e.preventDefault();
                    }}
                  >
                    <User name={userName} />
                  </NavLink>

                  {/*<div className={classes.modal}>
                    <ul className={classes.modalList}>
                      <li
                        className={clsx(
                          classes.modalItem,
                          disableHeader && classes.disabled
                        )}
                      >
                        <NavLink
                          to={path}
                          className={(navData) =>
                            clsx(
                              classes.modalLink,
                              navData.isActive &&
                                !disableHeader &&
                                classes.modalLinkActive
                            )
                          }
                        >
                          <div className={classes.modalInner}>
                            <div className={classes.modalIcon}>
                              <ShapeGradientIcon />
                            </div>
                            <span className={classes.modalName}>
                              {t('Profile')}
                            </span>
                          </div>
                        </NavLink>
                      </li>

                      {(isAdmin || isManager) && (
                        <li
                          className={clsx(
                            classes.modalItem,
                            disableHeader && classes.disabled
                          )}
                        >
                          <NavLink
                            to={`${process.env.REACT_APP_API}/globaladmin/`}
                            className={classes.modalLink}
                          >
                            <div className={classes.modalInner}>
                              <div className={classes.modalIcon}>
                                <AdminGradientIcon />
                              </div>
                              <span className={classes.modalName}>
                                {t('Admin panel')}
                              </span>
                            </div>
                          </NavLink>
                        </li>
                      )}

                      {!isTestUser && (
                        <li className={classes.modalItem}>
                          <Button
                            onClick={resetCookies}
                            className={classes.modalButton}
                          >
                            <div className={classes.modalInner}>
                              <div className={classes.modalIcon}>
                                <LogoutGradientIcon />
                              </div>
                              <span className={classes.modalName}>
                                {t('Log out')}
                              </span>
                            </div>
                          </Button>
                        </li>
                      )}
                    </ul>
                  </div>*/}
                </li>
              </ul>
            </div>

            {width <= 1024 && active && (
              <ul className={classes['header__admin-list']}>
                {(isAdmin || isManager) && (
                  <li
                    className={clsx(
                      classes['header__admin-list-item'],
                      disableHeader && classes.disabled
                    )}
                  >
                    <NavLink
                      to={`${process.env.REACT_APP_API}/globaladmin/`}
                      className={classes.modalLink}
                    >
                      <div className={classes.modalInner}>
                        <div className={classes.modalIcon}>
                          <AdminGradientIconActive />
                        </div>
                        <span className={classes.modalName}>
                          {t('Admin panel')}
                        </span>
                      </div>
                    </NavLink>
                  </li>
                )}
                {!RUSSIAN_LOCALE && (
                  <li
                    className={clsx(
                      classes['header__admin-list-item'],
                      // disableHeader && classes.disabled
                    )}
                  >
                    <div
                      className={classes.modalLink}
                      onClick={handleOpenLocalPreferencesModal}
                    >
                      <div className={classes.modalInner}>
                        <div className={classes.modalIcon}>
                          <GlobusGradientIcon />
                        </div>
                        <span className={classes.modalName}>
                          {t('Local preferences')}
                        </span>
                      </div>
                    </div>
                  </li>
                )}

                {/* {!isTestUser && (
                  <li className={classes['header__admin-list-item']}>
                    <Button
                      onClick={resetCookies}
                      className={clsx(classes.modalButton)}
                    >
                      <div className={classes.modalInner}>
                        <div className={classes.modalIcon}>
                          <LogoutGradientIcon />
                        </div>
                        <span className={classes.modalName}>
                          {t('Log out')}
                        </span>
                      </div>
                    </Button>
                  </li>
                )} */}
              </ul>
            )}

            {active && isMediaTablet && (
              <footer className={clsx(classes['burger-footer'])}>
                <div className={classes['burger-footer__inner']}>
                  <div
                    className={classes['burger-footer__copy']}
                    translate="no"
                  >
                    &copy; {currentYear} Kokoc Group
                  </div>
                  <LinksPolitic />
                </div>
              </footer>
            )}
          </nav>

          {width > 1024 ? (
            <Modal
              isOpen={isOpenLocalPreferencesModal}
              title={t('Local preferences')}
              onClose={handleCloseLocalPreferencesModal}
              className=""
              // isBasicHeader={true}
            >
              <LocalPreferencesForm
                onClose={handleCloseLocalPreferencesModal}
                onSave={handleSendLocalPreferences}
                success={isSuccessLocalPreferences}
                disabled={isDisabledLocalPreferences}
                // noticeText={t(
                //   'Local settings affect the interface language and prices you see on the platform'
                // )}
                // countries={countries}
                // currencies={currencies}
                languages={languages}
                // currentCountry={userCountry}
                // currentCurrency={userCurrency}
                currentLanguage={languages.find(
                  (item) => item.lang_code === i18n.language
                )}
                disabledFields={['country']}
              />
            </Modal>
          ) : null}

          {width <= 1024 ? (
            <ModalMobile
              isOpen={isOpenLocalPreferencesModal}
              title={t('Language')}
              onClose={handleCloseLocalPreferencesModal}
              subModal={true}
            >
              <LocalPreferencesFormMobile
                onClose={handleCloseLocalPreferencesModal}
                onSave={handleSendLocalPreferences}
                success={isSuccessLocalPreferences}
                disabled={isDisabledLocalPreferences}
                languages={languages}
                currentLanguage={languages.find(
                  (item) => item.lang_code === i18n.language
                )}
                disabledFields={['country']}
              />
            </ModalMobile>
          ) : null}
        </header>

        {width <= 1024 && !active && (
          <HeaderButtons
            bottomMenuSize={bottomMenuSize}
            disableHeader={disableHeader}
            disableUserLogout={disableUserLogout}
            path={path}
            allowLogout={allowLogout}
            userName={userName}
            isSuccessLocalPreferences={isSuccessLocalPreferences}
            isActiveLink={isActiveLink}
          />
        )}
      </>
    );
  }
);

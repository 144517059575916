import React, { useEffect } from 'react';
import clsx from 'clsx';
import classes from './LocalPreferencesMobile.module.scss';
import { LocalPreferencesFormProps } from './LocalPreferencesForm.props';
import { CheckLanguage } from 'components/shared/Icons/CheckLanguage';

export const LocalPreferencesFormMobile = ({
  onSave,
  setIsDisabled,
  languages,
  currentLanguage,
}: LocalPreferencesFormProps) => {
  useEffect(() => {
    setIsDisabled?.(!currentLanguage);
  }, [setIsDisabled]);

  return (
    <div>
      {languages?.map((language) => (
        <div
          key={language.id}
          className={clsx(classes.language, {
            [classes.language__selected]: currentLanguage?.id === language?.id,
          })}
          onClick={() => onSave({ language: language.lang_code })}
        >
          <div className={classes.languageName}>{language.name}</div>
          {currentLanguage?.id === language?.id && <CheckLanguage />}
        </div>
      ))}
    </div>
  );
};

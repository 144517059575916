import { PlusIcon } from 'components/shared/Icons/PlusIcon';
import classes from './TableAccordionHeader.module.scss';
import { MinusIcon } from 'components/shared/Icons/MinusIcon';
import { TimerIcon } from 'components/shared/Icons/TimerIcon';
import { CheckCircleIcon } from 'components/shared/Icons/CheckCirleIcon';
import { CancelledIcon } from 'components/shared/Icons/CancelledIcon';
import { CodeCircleIcon } from 'components/shared/Icons/CodeCircleIcon';
import clsx from 'clsx';
import FormatPrice from 'components/shared/FormatPriceAndCurrency';
import { TFunction } from 'i18next';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { StatusAmount } from '../ProjectsTable';
import { ProjectType } from '../types';
import { useTranslation } from 'react-i18next';
import { Currency } from '@type/finance';
import React from 'react';

type Props = {
  t: TFunction;
  title: string;
  open: boolean;
  setOpen: () => void;
  currency: Currency;
  totalCost: number;
  statusAmount: StatusAmount;
  projects: ProjectType[];
};

function TableAccordionHeader(props: Props) {
  const { t } = useTranslation();
  const { active, completed, paused, cancelled } = props.statusAmount || {};
  const { projects, currency } = props;
  const projectsAmount =
    projects?.length === 1
      ? t('{{word}} project', { word: projects?.length })
      : t('{{word}} projects', { word: projects?.length });
  return (
    <div className={classes.header}>
      <div className={classes.header__item}>
        <button
          className={classes.header__button}
          onClick={() => props.setOpen()}
        >
          {props.open ? <MinusIcon /> : <PlusIcon />}
        </button>
      </div>
      <div className={classes.header__item}>
        <span className={classes.header__title}>{props.title}</span>
      </div>
      <div className={classes.header__item}>
        <div className={classes.header__statuses}>
          <div
            className={clsx(
              classes['header__statuses--item'],
              classes['header__statuses--item_first']
            )}
          >
            <TooltipContainer
              text={t('Active')}
              position={'bottom'}
              className={classes.tooltipContainer}
              classNameTooltip={classes.tooltip}
              noPointer
            >
              <TimerIcon />
            </TooltipContainer>
            <span>{active}</span>
          </div>
          <div
            className={clsx(
              classes['header__statuses--item'],
              classes['header__statuses--item_second']
            )}
          >
            <TooltipContainer
              text={t('Paused')}
              position={'bottom'}
              className={classes.tooltipContainer}
              classNameTooltip={classes.tooltip}
              noPointer
            >
              <CodeCircleIcon />
            </TooltipContainer>
            <span>{paused}</span>
          </div>
          <div
            className={clsx(
              classes['header__statuses--item'],
              classes['header__statuses--item_third']
            )}
          >
            <TooltipContainer
              text={t('Completed')}
              position={'bottom'}
              className={classes.tooltipContainer}
              classNameTooltip={classes.tooltip}
              noPointer
            >
              <CheckCircleIcon />
            </TooltipContainer>
            <span>{completed}</span>
          </div>
          <div
            className={clsx(
              classes['header__statuses--item'],
              classes['header__statuses--item_fourth']
            )}
          >
            <TooltipContainer
              text={t('Cancelled')}
              position={'bottom'}
              className={classes.tooltipContainer}
              classNameTooltip={classes.tooltip}
              noPointer
            >
              <CancelledIcon />
            </TooltipContainer>
            <span>{cancelled}</span>
          </div>
        </div>
      </div>
      <div className={classes.header__item}></div>
      <div className={classes.header__item}>
        <span className={classes.header__projects}>{projectsAmount}</span>
      </div>
      <div className={classes.header__item}>
        <span className={classes.header__cost}>
          <FormatPrice currency={currency?.symbol} price={props?.totalCost} />
        </span>
      </div>
    </div>
  );
}

export default TableAccordionHeader;

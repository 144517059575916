import clsx from 'classnames';
import React from 'react';
import MultiSelect from 'components/shared/MultiSelect';
import { SelectData } from 'components/shared/SelectComponent';
import { Controller } from 'react-hook-form';
import Input from 'components/shared/NewInput';
import { Textarea } from 'components/shared/NewInput/TextArea';
import { Select } from 'components/shared/Select';
import { multiFieldValues, writeRef } from '../utils';
import classes from './AddCaseForm.module.scss';
import { AddCaseFormProps } from './AddCaseForm.props';
import { KPIFormContainer } from './KPIForm';
import { useAddCaseForm } from './useAddCaseForm';
import { useCommonStore } from 'contexts/CommonContext';
import { addCaseMessages } from '../FormBlock/validationSchemas';

export const AddCaseForm = ({
  caseItem,
  disabled,
  caseNumber = 1,
  onRemoveCase,
  platforms,
  fieldRefs,
  currentStep,
}: AddCaseFormProps) => {
  const businessTypes = useCommonStore().dictionaries?.business_types || [];
  const {
    t,
    i18n,
    generalCount,
    endIconOptions,
    errors,
    control,
    setSelectBusinessTypes,
    selectPlatforms,
    callbacks,
    formattedValue,
    internalPlatforms,
    internalBusinessTypes,
    setInternalBusinessTypes,
    clearErrors,
  } = useAddCaseForm(
    caseNumber,
    disabled,
    platforms,
    businessTypes,
    currentStep,
    fieldRefs
  );

  return (
    <div className={classes.caseWrapper}>
      <div
        data-step-add_cases_p-0
        className={clsx(classes.hint, classes['hint--1'])}
      >
        <div className={clsx(classes.title)}>
          <div className={classes.title__withCounter}>
            <span>{`${t('Case')} ${caseNumber + 1}`}</span>
            <span className={classes.counter}>
              {generalCount} {t('out of 3 filled')}
            </span>
          </div>
          <button
            disabled={disabled}
            className={clsx(classes.deleteButton, disabled && classes.disabled)}
            onClick={onRemoveCase}
          >
            {t('Remove')}
          </button>
        </div>

        <div
          ref={(element) =>
            writeRef(
              !!errors?.cases?.[caseNumber]?.caseName,
              fieldRefs,
              element,
              `cases.${caseNumber}.caseName`
            )
          }
        >
          <Controller
            render={({ field }) => (
              <Input
                placeholder={t('Name your case')}
                classNames={classes.input}
                onChange={(e: any) => {
                  field.onChange(
                    e.replaceAll(/\s{2,}/g, ' ').replace(/^\s/g, '')
                  );
                  clearErrors(`cases.${caseNumber}.caseName`);
                }}
                endIcon={endIconOptions(addCaseMessages(t).case_name)}
                maxLength={50}
                value={field.value}
                title={t('Name your case')}
                error={errors?.cases?.[caseNumber]?.caseName?.message as string}
                errorText={errors?.cases?.[caseNumber]?.caseName?.message}
              />
            )}
            name={`cases.${caseNumber}.caseName`}
            control={control}
          />
        </div>

        <div
          ref={(element) =>
            writeRef(
              !!errors?.cases?.[caseNumber]?.caseBusinessType,
              fieldRefs,
              element,
              `cases.${caseNumber}.caseBusinessType`
            )
          }
        >
          <Controller
            render={({ field }) => {
              return (
                <Select
                  title={t('Business type')}
                  placeholder={t('Select category')}
                  endIcon={endIconOptions(addCaseMessages(t).business_types)}
                  isSearch
                  className={classes.select}
                  select={businessTypes?.find(
                    (item: any) => item?.id === field?.value
                  )}
                  options={internalBusinessTypes}
                  defaultValue={caseItem && { name: field.value }}
                  setSelect={setSelectBusinessTypes}
                  error={errors?.cases?.[caseNumber]?.caseBusinessType?.message}
                  onSelect={(item: SelectData) => {
                    field.onChange(item?.id);
                    clearErrors(`cases.${caseNumber}.caseBusinessType`);
                  }}
                  searchPlaceholder={t('Search')}
                  isSolutionForm
                  onSearch={(value) => {
                    businessTypes &&
                      setInternalBusinessTypes(
                        businessTypes?.filter((item) =>
                          String(item?.name)
                            ?.toLowerCase()
                            ?.includes(value.toLowerCase())
                        )
                      );
                  }}
                />
              );
            }}
            name={`cases.${caseNumber}.caseBusinessType`}
            control={control}
          />
        </div>
      </div>

      <div
        data-step-add_cases_p-1
        className={clsx(classes.hint, classes['hint--2'])}
      >
        <div
          ref={(element) =>
            writeRef(
              !!errors?.cases?.[caseNumber]?.platforms,
              fieldRefs,
              element,
              `cases.${caseNumber}.platforms`
            )
          }
        >
          <Controller
            render={({ field }) => (
              <MultiSelect
                title={t('Platforms')}
                isSolutionForm
                endIcon={endIconOptions(addCaseMessages(t).platforms)}
                additionalClassName={classes.multiselectWrapper}
                options={internalPlatforms}
                contentWidth={'103%'}
                multiline
                selected={multiFieldValues(selectPlatforms, field, i18n, true)}
                onSelect={(id) => {
                  callbacks.onSelectProduct(id, field.onChange);
                  clearErrors(`cases.${caseNumber}.platforms`);
                }}
                onDeleteSelected={(id) => {
                  callbacks.onDeleteSelectedProduct(id, field.onChange);
                  selectPlatforms.length === 1 && field.onChange(null);
                }}
                placeholder=""
                error={errors?.cases?.[
                  caseNumber
                ]?.platforms?.message?.toString()}
              />
            )}
            name={`cases.${caseNumber}.platforms`}
            control={control}
          />
        </div>

        <KPIFormContainer
          caseNumber={caseNumber}
          initialSteps={caseItem?.kpi}
          fieldRefs={fieldRefs}
        />
      </div>
    </div>
  );
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { LABELS } from 'constants/onboarding';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { Steps } from 'intro.js-react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/shared/Button';
import UiIcon from 'components/shared/Icon';
import { Pagination } from 'components/shared/Pagination';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { PartnerCard } from './Card';
import EmptyBlock from './EmptyBlock/EmptyBlock';
import { Filters } from './Filters';
import ModerateBlock from './ModerateBlock/ModerateBlock';
import classes from './PartnerContainer.module.scss';
import { PARTNER_COUNTER, usePartnerContainer } from './usePartnerContainer';
import 'intro.js/introjs.css';
import {
  completeOnboarding,
  findOnboardingSteps,
  formatSteps,
  OnboardingSteps,
} from 'tools/onboarding';
import { DISABLE_TRANSLATION } from 'constants/languages';

export const PartnerContainer = observer(({ title }: { title: string }) => {
  const {
    t,
    services,
    servicesCount,
    clearCopyOfService,
    statusCount,
    filter,
    setFilter,
    isOnModerate,
    cardInfo,
    currentPage,
    changeChunks,
    setCurrentPage,
    onPaginate,
    goToTop,
    isPartnerViewer,
  } = usePartnerContainer();

  const isEmpty = services?.length === 0;
  const totalPages = servicesCount / PARTNER_COUNTER;
  const activeCount = statusCount.active;
  const inReviewCount = statusCount.in_review;
  const archivedCount = statusCount.archived;
  const rejectedCount = statusCount.rejected;
  const navigate = useNavigate();

  const { GOT_IT, NEXT, BACK } = LABELS(t);
  const isMobile = useWindowWidth().isMediaTablet;
  const [onboarding, setOnboarding] = useState<OnboardingSteps | null>(null);

  const onboardingPath = isEmpty ? 'EMPTY_SOLUTIONS_P' : 'SOLUTIONS_P';
  const hasCompletedOnboarding = useRef<
    'EMPTY_SOLUTIONS_P' | 'SOLUTIONS_P' | null
  >(null);
  const isButtonDisabled = isOnModerate || isPartnerViewer;

  useEffect(() => {
    if (!isButtonDisabled) {
      findOnboardingSteps(onboardingPath).then((res) => {
        setOnboarding(res);
      });
    }
  }, [onboardingPath, isButtonDisabled]);

  const completeCurrentOnboarding = useCallback(() => {
    if (hasCompletedOnboarding.current === onboardingPath) {
      return;
    }

    completeOnboarding(onboarding?.id);
    setOnboarding(null);
    hasCompletedOnboarding.current = onboardingPath;
  }, [onboarding?.id, onboardingPath]);

  return (
    <div className={classes.container} translate={DISABLE_TRANSLATION}>
      {!isMobile && onboarding && !isButtonDisabled ? (
        <Steps
          enabled={true}
          steps={formatSteps(onboardingPath, onboarding.steps)}
          options={{
            tooltipClass: 'customTooltip',
            showBullets: true,
            doneLabel: GOT_IT,
            nextLabel: NEXT,
            prevLabel: BACK,
          }}
          initialStep={0}
          onExit={completeCurrentOnboarding}
        />
      ) : null}

      <TitleAndDescription pageName="Solutions" />

      <div className={classes.title}>
        {title}
        <span>{servicesCount}</span>
      </div>

      <div className={classes.filterBlock}>
        <Filters
          all={servicesCount}
          active={activeCount}
          inReview={inReviewCount}
          archived={archivedCount}
          rejected={rejectedCount}
          filter={isEmpty || isOnModerate ? null : filter}
          setFilter={setFilter}
        />

        <Button
          disabled={isOnModerate || isPartnerViewer}
          theme="primary"
          className={classes.mainButton}
          onClick={() => {
            clearCopyOfService();
            navigate('/solutions/add-solution/');
          }}
        >
          {(isEmpty || isOnModerate) && (
            <UiIcon name="PlusIcon" additionalClassName={classes.plusIcon} />
          )}
          {t('Add solution')}
        </Button>
      </div>

      <ul className={classes.cardBlock}>
        {isOnModerate ? (
          <ModerateBlock />
        ) : isEmpty ? (
          <EmptyBlock />
        ) : (
          cardInfo?.map((card: any, index: number) => (
            <PartnerCard
              cardHit={index === 0 ? 'data-step-solutions_p-0' : ''}
              key={index}
              project={card}
            />
          ))
        )}

        {!isOnModerate && !isEmpty && (
          <div className={classes.pagination}>
            {statusCount.current_filter > PARTNER_COUNTER && (
              <Pagination
                currentPage={currentPage}
                totalPages={changeChunks()?.length}
                setPageNumber={(page: number) => {
                  setCurrentPage(page);
                  onPaginate(page);
                  goToTop();
                }}
                nextPage={() =>
                  currentPage > 0 &&
                  currentPage < totalPages &&
                  setCurrentPage(currentPage + 1)
                }
                prevPage={() =>
                  currentPage !== 0 && setCurrentPage(currentPage - 1)
                }
              />
            )}
          </div>
        )}
      </ul>
    </div>
  );
});

import * as React from 'react';
import { SVGProps } from 'react';

export const CheckLanguage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      d="M16.6666 5L7.49992 14.1667L3.33325 10"
      stroke="#8362F3"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);

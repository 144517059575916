import { routers } from 'constants/routers';
import { useAuthStore } from 'contexts/AuthContext';
import { useUserStore } from 'contexts/UserContext';
import { useOpenMenu } from 'hooks/useOpenMenu';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ChangeUser } from 'services/api/changeUser';
// import { GetCountries } from 'services/api/getCountries';
// import { GetCurrencies } from 'services/api/getCurrencies';
import { commonStore } from 'stores/commonStore';
import { ReloadStores } from 'tools/reloadStores';
import { ShowNotification } from 'tools/showNotification';

export const useHeader = () => {
  const authStore = useAuthStore();
  const userStore = useUserStore();
  // const { currency, country } = userStore?.user || {};
  const [cookies, setCookies] = useCookies(['access', 'refresh']);

  const isTestUser =
    userStore?.user?.id === 566 ||
    userStore?.user?.email === 'partner@platform.kokoc';

  const [active, setActive] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isManager, setIsManager] = useState(false);
  // const [userCountry, setUserCountry] = useState<any>(country);
  // const [userCurrency, setUserCurrency] = useState<any>(currency);
  // const [countries, setCountries] = useState<any>([]);
  // const [currencies, setCurrencies] = useState<any>([]);
  const [isOpenLocalPreferencesModal, setIsOpenLocalPreferencesModal] =
    useState(false);
  const [isSuccessLocalPreferences, setIsSuccessLocalPreferences] =
    useState(true); //change to false when locale settings is needed
  const [isDisabledLocalPreferences, setIsDisabledLocalPreferences] =
    useState(false);
  const isDisabledModerate = !userStore.user?.moderated;

  const { i18n, t } = useTranslation();

  const [path, setPath] = useState('');
  const [userName, setUserName] = useState('');
  const [openSearch, setOpenSearch] = useState(false);
  const [bottomMenuSize, setBottomMenuSize] = useState(56);

  const { isMediaTablet, width } = useWindowWidth();

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    isMediaTablet ? setPath(routers.account) : setPath(routers.personal);
  }, [isMediaTablet]);

  const openMenu = () => {
    setActive(true);
  };

  const closeMenu = () => {
    setActive(false);
  };

  useEffect(() => {
    if (width > 1024) {
      closeMenu();
      setOpenSearch(false);
    }
  }, [width]);

  useOpenMenu(active);
  useOpenMenu(openSearch);

  const getParams = () => {
    if (userStore.user) {
      setUserName(userStore.user.full_name);
      setIsAdmin(userStore.user.is_superuser);
      setIsManager(userStore.user.is_staff);
      // setUserCountry(userStore.user.country);
      // setUserCurrency(userStore.user.currency);
    }
  };

  useEffect(() => {
    if (authStore.isAuth) {
      getParams();
    }
  }, [userStore.user]);

  let isActiveLink = false;
  const location = useLocation();

  const routersMatch = [
    routers.account,
    routers.personal,
    routers.business,
    routers.changePassword,
  ];

  routersMatch.includes(location.pathname)
    ? (isActiveLink = true)
    : (isActiveLink = false);

  const clearStore = () => {
    userStore.clearStore();
    commonStore.clearStore();
  };

  const resetCookies = async (e: any) => {
    e.preventDefault();
    clearStore();
    await authStore.logout();
  };

  // const getCountries = async () => {
  //   try {
  //     const response = await GetCountries({
  //       access: cookies.access,
  //       refresh: cookies.refresh,
  //     });
  //     setCountries(response);
  //   } catch (error) {
  //     setCountries([]);
  //   }
  // };

  // const getCurrencies = async () => {
  //   try {
  //     const response = await GetCurrencies({
  //       access: cookies.access,
  //       refresh: cookies.refresh,
  //     });
  //     setCurrencies(response);
  //   } catch (error) {
  //     setCurrencies([]);
  //   }
  // };

  const handleOpenLocalPreferencesModal = () => {
    // getCountries();
    // getCurrencies();
    setIsOpenLocalPreferencesModal(true);
  };

  const handleCloseLocalPreferencesModal = () => {
    setIsOpenLocalPreferencesModal(false);
  };

  const handleSendLocalPreferences = async (data: any) => {
    setIsDisabledLocalPreferences(true);
    try {
      const response = await ChangeUser({
        access: cookies.access,
        data: data,
      });
      userStore.setUser(response.user);
      // setUserCountry(response.user.country);
      // setUserCurrency(response.user.currency);
      setIsSuccessLocalPreferences(true);
      ReloadStores();
      userStore.setLocaleChanged(true);
      i18n.changeLanguage(response.user.language.lang_code, (error, t) => {
        if (error) {
          ShowNotification({
            type: 'error',
            children: t('An error occurred. Please try again later'),
          });
        } else {
          ShowNotification({
            type: 'success',
            children: t('Changes saved successfully'),
          });
        }
      });
      setIsOpenLocalPreferencesModal(false);
    } catch (error) {
      console.error(error);
      ShowNotification({
        type: 'error',
        children: t('An error occurred. Please try again later'),
      });
    }
    setIsDisabledLocalPreferences(false);
  };

  const disableHeader =
    !isSuccessLocalPreferences ||
    !userStore?.user?.email_confirmed ||
    isDisabledModerate ||
    authStore.isServerError;

  const disableUserLogout =
    !isSuccessLocalPreferences || !userStore?.user?.email_confirmed;

  return {
    width,
    active,
    bottomMenuSize,
    disableHeader,
    t,
    isSuccessLocalPreferences,
    isActiveLink,
    path,
    userName,
    openSearch,
    setOpenSearch,
    closeMenu,
    openMenu,
    isDisabledModerate,
    isManager,
    isAdmin,
    resetCookies,
    isTestUser,
    isDisabledLocalPreferences,
    handleSendLocalPreferences,
    handleCloseLocalPreferencesModal,
    handleOpenLocalPreferencesModal,
    isOpenLocalPreferencesModal,
    isMediaTablet,
    i18n,
    // userCountry,
    // currencies,
    // countries,
    // userCurrency,
    disableUserLogout,
    currentYear,
  };
};

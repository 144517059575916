import { Grid, GridItem } from 'components/common/Grid';
import { Button } from 'components/shared/Button';
// import { MultiselectComponent } from 'components/shared/MultiselectComponent';
// import { Notice } from 'components/shared/Notice';
import { useWindowWidth } from 'hooks/useWindowWidth';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classes from './LocalPreferences.module.scss';
import {
  FormValues,
  LocalPreferencesFormProps,
} from './LocalPreferencesForm.props';
import { Select } from 'components/shared/Select';
import { SelectData } from 'components/shared/SelectComponent';

export const LocalPreferencesForm = ({
  onClose,
  onSave,
  disabled,
  disabledFields,
  setIsDisabled,
  onlySaveButton,
  // noticeText,
  // countries,
  // currencies,
  languages,
  currentLanguage,
  // currentCountry,
  // currentCurrency,
}: LocalPreferencesFormProps) => {
  const { t } = useTranslation();
  const { control } = useForm<FormValues>();
  const { isMediaTablet } = useWindowWidth();
  // const [selectedCountry, setSelectedCountry] = useState<any>(currentCountry);
  // const [selectedCurrency, setSelectedCurrency] =
  //   useState<any>(currentCurrency);
  const [selectedLanguage, setSelectedLanguage] =
    useState<any>(currentLanguage);

  const handleSave = () => {
    const requestData = {
      language: selectedLanguage.lang_code,
      // country: { id: selectedCountry?.id || currentCountry?.id },
      // currency: { id: selectedCurrency?.id || currentCurrency?.id },
    };
    onSave(requestData);
  };

  useEffect(() => {
    if (setIsDisabled) {
      if (
        // (selectedCountry || currentCountry) &&
        // selectedCurrency &&
        selectedLanguage
      ) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
    // }, [selectedCountry, selectedCurrency, selectedLanguage]);
  }, [selectedLanguage]);

  return (
    <form
      name="change-language"
      className={classes.form}
    >
      <div className={classes.inner}>
        {!isMediaTablet && !onlySaveButton && (
          <div className={classes.title}>{t('Local preferences')}</div>
        )}
        {/* <div className={classes['notice-container']}>
          <Notice
            type="warning"
            noticeClass={classes.notice}
            noticeIconClass={classes['notice-icon']}
            childrenClass={classes['notice-text']}
          >
            {noticeText}
          </Notice>
        </div> */}

        <Grid direction="row" className={classes.box}>
          <GridItem col={{ default: 12 }} className={classes.item}>
            {/* <div className={classes['item-label']}>{t('Currency')}</div>
            <Controller
              render={() => (
                <MultiselectComponent
                  data={currencies}
                  defaultSelected={currentCurrency?.id}
                  placeholder="currency"
                  setMultiselect={setSelectedCurrency}
                  search={false}
                  disabled={disabledFields?.includes('currency')}
                />
              )}
              name="currency"
              control={control}
            />
          </GridItem>

          <GridItem col={{ default: 12 }} className={classes.item}>
            <div className={classes['item-label']}>{t('Country')}</div>
            <Controller
              render={() => (
                <MultiselectComponent
                  data={countries}
                  defaultSelected={currentCountry?.id}
                  placeholder="country"
                  setMultiselect={setSelectedCountry}
                  search={true}
                  disabled={disabledFields?.includes('country')}
                />
              )}
              name="country"
              control={control}
            />
          </GridItem> */}

            {/* <GridItem col={{ default: 12 }} className={classes.item}> */}
            {/* <div className={classes['item-label']}>{t('Language')}</div> */}
            {/* <Controller
              render={() => (
                <MultiselectComponent
                  data={languages}
                  defaultSelected={currentLanguage?.id}
                  placeholder={t('Language')}
                  setMultiselect={setSelectedLanguage}
                  search={false}
                  disabled={disabledFields?.includes('language')}
                  // innerLabel={t('Language')}

                />
              )}
              name="language"
              control={control}
            /> */}
            <Controller
              render={({ field }) => (
                <Select
                  title={t('Languages')}
                  placeholder={t('Select category')}
                  select={selectedLanguage}
                  options={languages}
                  onSelect={(item: SelectData) => {
                    field.onChange(item?.id);
                    setSelectedLanguage(item);
                  }}
                />
              )}
              name="language"
              control={control}
            />
          </GridItem>
        </Grid>

        <div className={classes.bottom}>
          {!onlySaveButton && !isMediaTablet && (
            <Button
              theme="light"
              type="button"
              size="middle"
              className={classes.btn}
              onClick={onClose}
            >
              {t('Back')}
            </Button>
          )}

          <Button
            theme="primary"
            type="button"
            size="middle"
            disabled={disabled}
            className={classes.btn}
            onClick={handleSave}
          >
            {t('Save')}
          </Button>
        </div>
      </div>
    </form>
  );
};

import clsx from 'clsx';
import Autocomplete from 'components/common/Autocomplete';
import { Button } from 'components/shared/Button';
import { Checkbox } from 'components/shared/Checkbox/Checkbox';
import { InfoIcon } from 'components/shared/Icons/InfoIcon';
import Input from 'components/shared/NewInput';
import { Link } from 'components/shared/Link';
import MultiSelect from 'components/shared/MultiSelect';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { defaultLanguage } from 'i18n/i18n';
import { Controller } from 'react-hook-form';
import classes from './RegistrationFormRu.module.scss';
import { useRegistrationFormRu } from './useRegistrationFormRu';
import { observer } from 'mobx-react-lite';
import { RUSSIAN_LOCALE } from 'utils';
import React, { useState } from 'react';
import { useWindowWidth } from 'hooks/useWindowWidth';

export const RegistrationFormRu = observer(() => {
  const {
    formRef,
    handleSubmit,
    onSubmit,
    errors,
    t,
    control,
    selectedLocation,
    locationOptions,
    callbacks,
    selectedProducts,
    onLoadProducts,
    productsOptions,
    internalProducts,
    setInternalProducts,
    isLoadingProducts,
    i18n,
    isDisabled,
    setAgree,
    isInvite
  } = useRegistrationFormRu();

  const [openModal, setOpenModal] = useState(false);
  const { isMediaTablet } = useWindowWidth();

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit(onSubmit)}
      className={classes.form}
    >
      <div className={classes.headerWrapper}>
        <h3 className={classes.headerTitle}>
          {isInvite
              ? t(`Agreement with the platform's documents`)
              : t('You are welcome!')}
          {t('You are welcome!')}
          </h3>
        <p className={classes.headerDescription}>
            {isInvite
              ? t(
                  'Review the user documentation of the platform and confirm your consent to registration'
                )
              : t('Tell us about your business - we will offer clients who are interested in your expertise')}
        </p>
      </div>

      {
        !isInvite && (
          <>
                  <div className={classes['top-fields']}>
        <div className={classes['input-field']}>
          <Controller
            render={({ field }) => (
              <Input
                title={t('Company name')}
                maxLength={120}
                type={'text'}
                placeholder={t('Company name')}
                value={field.value}
                onChange={field.onChange}
                error={errors?.companyName?.message}
                errorText={errors?.companyName?.message}
                wrapperClassName={classes.input}
                focusClassName={classes.input__focused}
              />
            )}
            control={control}
            name="companyName"
          />
        </div>

        <div className={classes['input-field']}>
          <Controller
            control={control}
            render={({ field }) => (
              <Input
                maxLength={50}
                type={'text'}
                title={t(`Company's website`)}
                placeholder={t(`Company's website`)}
                value={field.value}
                onChange={field.onChange}
                error={errors?.companyUrl?.message}
                errorText={errors?.companyUrl?.message}
                wrapperClassName={classes.input}
                focusClassName={classes.input__focused}
              />
            )}
            name="companyUrl"
          />
        </div>

        <div className={classes['input-field']}>
          <Controller
            render={({ field }) => (
              <Input
                maxLength={254}
                type={'text'}
                title={t('Job title')}
                placeholder={t('Job title')}
                value={field.value}
                onChange={field.onChange}
                error={errors?.jobTitle?.message}
                errorText={errors?.jobTitle?.message}
                wrapperClassName={classes.input}
                focusClassName={classes.input__focused}
              />
            )}
            control={control}
            name="jobTitle"
          />
        </div>

        <div className={clsx(classes['input-field'])}>
          <Controller
            name="location"
            control={control}
            render={() => (
              <Autocomplete
                options={locationOptions}
                selected={
                  selectedLocation && {
                    value: selectedLocation.id,
                    label: selectedLocation.name,
                  }
                }
                onSelect={(id) => callbacks.onSelectLocation(Number(id))}
                placeholder={t(`Country's office`)}
                mainInputClassName={clsx(classes.input, classes.disabled)}
                focusClassName={classes.input__focused}
                otherFieldSearch
                searchPlaceholder={t(`Search`)}
              />
            )}
          />
          {errors?.location && (
            <TooltipContainer
              text={errors?.location?.message}
              customClasses={'kit-ui-block'}
              position={'top'}
              className={classes.error}
            >
              <InfoIcon fill="#F5222D" width="18px" height="18px" />
            </TooltipContainer>
          )}
        </div>
      </div>

      <div
        className={clsx(classes['input-field'], classes['input-field--full'])}
      >
        <Controller
          name="userProducts"
          control={control}
          render={() => (
            <MultiSelect
              title={t(`Company's products`)}
              fakeInputClassName={classes.fakeInput}
              mainInputClassName={classes.mainInput}
              mainInputExpandedClassName={classes.mainInputExpanded}
              additionalClassName={classes.multiselect}
              focusClassName={classes.multiselect__focused}
              isLoading={isLoadingProducts}
              options={internalProducts}
              scrollDebounce={300}
              onScrollBottom={onLoadProducts}
              selected={selectedProducts.map((product) => ({
                value: product.id,
                label: product.name,
              }))}
              onSelect={(id) => callbacks.onSelectProduct(id)}
              onDeleteSelected={(id) => callbacks.onDeleteSelectedProduct(id)}
              placeholder=""
              isSolutionForm
              multiline
              contentHeight="50px"
              contentWidth={isMediaTablet ? '103%' : 'unset'}
              showCounter
              isSearch
              withOrder
              onSearch={(search) => {
                setInternalProducts(
                  productsOptions.filter(({ value, label }) =>
                    label?.toLowerCase()?.includes(search?.toLowerCase())
                  )
                );
              }}
              searchPlaceholder={t(`Search`)}
            />
          )}
        />

        
        {errors?.userProducts && (
          <TooltipContainer
            text={errors?.userProducts?.message}
            customClasses={'kit-ui-block'}
            position={'top'}
            className={classes.error}
          >
            <InfoIcon fill="#F5222D" width="18px" height="18px" />
          </TooltipContainer>
        )}
      </div>
          </>
        )
      }



      <div className={classes['checkbox-block']}>
        <Controller
          render={({ field }) => (
            <>
              <Checkbox
                onChange={(e) => {
                  setAgree(e);
                  field.onChange(e);
                }}
                checked={field.value}
              />
              <div className={classes.text}>
                <span>{t('I agree to KIT Global')} </span>
                {/* <Link
                  className={classes.link}
                  href={
                    RUSSIAN_LOCALE
                      ? process.env.REACT_APP_RU_PARTNER_OFFER_URL || ''
                      : `https://kit.global/${
                          i18n.language !== defaultLanguage
                            ? `${i18n.language}/`
                            : ''
                        }terms/`
                  }
                  target={'_blank'}
                >
                  {t('terms of Service (with)')}
                </Link> */}

                <Link
                  className={classes.link}
                  href={`${process.env.REACT_APP_PRIVACY_POLICY_URL}`}
                  target={'_blank'}
                >
                  {t('privacy Policy (with)')}
                </Link>
                <span>{t('and')}</span>
                <Link
                  className={classes.link}
                  href={
                    RUSSIAN_LOCALE
                      ? process.env.REACT_APP_RU_PARTNER_OFFER_URL || ''
                      : `https://kit.global/${
                          i18n.language !== defaultLanguage
                            ? `${i18n.language}/`
                            : ''
                        }terms/`
                  }
                  target={'_blank'}
                >
                  {t('affiliate offer')}
                </Link>
              </div>
            </>
          )}
          name="iAgree"
          control={control}
        />
      </div>

      <div className={classes.submit}>
        <Button
          type="submit"
          className={clsx(classes.button, classes['submit-button'])}
          theme="primary"
          disabled={!isDisabled}
          onClick={() => setOpenModal(true)}
        >
          {t('Create account')}
        </Button>
      </div>
    </form>
  );
});

import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import classes from './ProjectsDetail.module.scss';
import { NavLink } from 'react-router-dom';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { ProjectsDealStages } from './DealStage';
import FormatPrice from '../../components/shared/FormatPriceAndCurrency';
import ArrowUpRight from '../../components/shared/Icons/ArrowUpRight';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import EmptyProject from '../../components/shared/EmptyProducts/EmptyProducts';
import clsx from 'clsx';
import ProjectGoals from './ProjectGoals';
import ProjectDocuments from './ProjectDocuments';
import StatusSticker from 'containers/Projects/StatusSticker';
import { Steps } from 'intro.js-react';
import {
  completeOnboarding,
  findOnboardingSteps,
  formatSteps,
  OnboardingSteps,
} from 'tools/onboarding';
import { LABELS } from 'constants/onboarding';
import { RUSSIAN_LOCALE } from 'utils';
import { useProjectsStore } from 'contexts/ProjectsContext';
import defaultAvatarBlue from 'assets/img/Managers/AvatarBlue.svg';
import defaultAvatarRed from 'assets/img/Managers/AvatarRed.svg';
import { ProjectCost } from '@type/projects';
import { DISABLE_TRANSLATION } from 'constants/languages';

interface Props {}

export const ProjectsDetailContainer: FC<Props> = observer(() => {
  const { t } = useTranslation();
  const { isMediaTablet } = useWindowWidth();
  const { selectedProject: project } = useProjectsStore();

  const tabid1 = 1;
  const tabid2 = 2;
  const tabid3 = 3;

  const tabs = [
    {
      id: tabid1,
      label: t('stages'),
    },
    {
      id: tabid2,
      label: t('goals'),
    },
    {
      id: tabid3,
      label: t('documents'),
    },
  ];

  const [activeTab, setActivTab] = useState(tabs[0].id);
  const projectName = project?.name || '';
  const projectManager = project?.manager;
  const projectNumber = project?.number;
  const projectCost = project?.cost?.[0].cost || 0;
  const projectCurrencySymbol = project?.currency.symbol || '$';

  const source =
    (project?.source?.name &&
      (project?.source?.name?.includes('http')
        ? project?.source?.name
        : `https://${project?.source?.name}`)) ||
    '';

  const tabsId = 'project_tabs';

  const shortProjectName =
    projectName?.length > (isMediaTablet ? 20 : 30)
      ? `${projectName?.slice(0, isMediaTablet ? 20 : 30)}...`
      : projectName;
  const shortSource =
    source?.length > (isMediaTablet ? 35 : 60)
      ? `${source?.slice(0, isMediaTablet ? 35 : 60)}...`
      : source;

  const isMobile = useWindowWidth().isMediaTablet;
  const [onboarding, setOnboarding] = useState<OnboardingSteps | null>(null);
  const onboardingPath = 'PROJECT_DETAIL_P';
  const { GOT_IT, NEXT, BACK } = LABELS(t);

  const hasCompletedOnboarding = useRef(false);

  useEffect(() => {
    findOnboardingSteps('PROJECT_DETAIL_P').then((res) => setOnboarding(res));
  }, []);

  const completeCurrentOnboarding = useCallback(() => {
    if (hasCompletedOnboarding.current) {
      return;
    }

    completeOnboarding(onboarding?.id);
    setOnboarding(null);
    hasCompletedOnboarding.current = true;
  }, [onboarding?.id]);

  const defaultManagerImage = RUSSIAN_LOCALE
    ? defaultAvatarRed
    : defaultAvatarBlue;

  return (
    <>
      {!isMobile && onboarding ? (
        <Steps
          enabled={true}
          steps={formatSteps(onboardingPath, onboarding.steps)}
          options={{
            tooltipClass: 'customTooltip',
            showBullets: true,
            doneLabel: GOT_IT,
            nextLabel: NEXT,
            prevLabel: BACK,
            scrollToElement: false,
          }}
          initialStep={0}
          onExit={completeCurrentOnboarding}
        />
      ) : null}

      <TitleAndDescription pageName="ProjectsDetail" title={projectName} />
      <div className={classes.wrapper} translate={DISABLE_TRANSLATION}>
        <ul className={classes.breadcrumbs}>
          <li className={classes.breadcrumbs__item}>
            <NavLink to={'/projects/'} className={classes.breadcrumbs__link}>
              {t('Projects')}
            </NavLink>
          </li>
          <li className={classes.breadcrumbs__item}>
            <span className={classes.breadcrumbs__link}>
              {isMediaTablet ? shortProjectName : projectName}
            </span>
          </li>
        </ul>

        <h1 className={classes.project__header}>{projectName}</h1>
        <div className={clsx(classes.project__link)}>
          <div className={clsx(classes.project__link_item)}>
            <StatusSticker status={project?.status || 'active'} t={t} />
          </div>
          <div className={clsx(classes.project__link_item)}>
            <div className={clsx(classes.project__id)}>ID {projectNumber}</div>
          </div>
          {source && (
            <div className={clsx(classes.project__link_item)}>
              <NavLink to={source} target="_blank">
                {shortSource}
                <ArrowUpRight className={classes.arrowIcon} fill="#8362F3" />
              </NavLink>
            </div>
          )}
        </div>

        <div className={classes.grid}>
          <div className={classes.grid__main} data-step-project_detail_p-0>
            <div className={classes.project__tabs} id={tabsId}>
              {tabs.map((tab) => (
                <label key={tab.id} className={classes.project__tab}>
                  <input
                    type="radio"
                    name="project_tab"
                    id="stages"
                    onChange={() => {
                      setActivTab(tab.id);
                    }}
                    checked={activeTab === tab.id}
                  />
                  <div>{tab.label}</div>
                </label>
              ))}
            </div>

            {activeTab === tabid1 && <ProjectsDealStages project={project} />}

            {activeTab === tabid2 &&
              (project?.goals?.length ? (
                <ProjectGoals project={project} />
              ) : (
                <EmptyProject
                  additionalClass={classes.empty}
                  bottomText={t("You don't have any goals")}
                />
              ))}

            {activeTab === tabid3 &&
              (project?.files?.length ? (
                <ProjectDocuments project={project} t={t} />
              ) : (
                <EmptyProject
                  additionalClass={classes.empty}
                  bottomText={t("You don't have any documents")}
                />
              ))}
          </div>

          <div className={classes.grid__side}>
            <div className={classes.grid__side__card}>
              <div className={classes.grid__side__card__top}>
                <div className={classes.grid__side__card__text}>
                  <h4 className={classes.grid__side__card__header}>
                    <FormatPrice
                      currency={projectCurrencySymbol}
                      price={projectCost}
                    />
                  </h4>
                  <span className={classes.grid__side__card__par}>
                    {t('Project cost')}
                  </span>
                </div>
              </div>
            </div>

            <div className={classes.grid__side__card}>
              <div className={classes.grid__side__card__top}>
                <img
                  src={projectManager?.image || defaultManagerImage}
                  alt=""
                  className={classes.card__grid__card__img}
                  onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.src = defaultManagerImage;
                    target.onerror = null;
                  }}
                />
                <div className={classes.grid__side__card__text}>
                  <h4 className={classes.grid__side__card__header_mini}>
                    {projectManager?.name || t('Manager not assigned yet')}
                  </h4>
                  <span className={classes.grid__side__card__par}>
                    {t('Client manager')}
                  </span>
                </div>
              </div>
              {/* {isMediaTablet ? (
                <Button className={classes.grid__side__card__icon_btn}>
                  <MailIcon />
                </Button>
              ) : (
                <Button theme="light" className={classes.grid__side__card__btn}>
                  {t("Contact manager")}
                </Button>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

import * as React from 'react';
import { SVGProps } from 'react';

export const GlobusGradientIcon = (props: SVGProps<SVGSVGElement>) => {
  const gradientId = React.useId();

  return (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.78832 11.25H8.08626C8.26173 8.98792 9.02629 6.81748 10.2947 4.95168C7.34929 5.66175 5.10485 8.17015 4.78832 11.25ZM12 5.15939C10.6237 6.91218 9.78633 9.02873 9.59136 11.25H14.4086C14.2137 9.02873 13.3763 6.91218 12 5.15939ZM14.4086 12.75C14.2137 14.9713 13.3763 17.0878 12 18.8406C10.6237 17.0878 9.78633 14.9713 9.59136 12.75H14.4086ZM8.08626 12.75H4.78832C5.10485 15.8298 7.34929 18.3383 10.2947 19.0483C9.02629 17.1825 8.26173 15.0121 8.08626 12.75ZM13.7053 19.0483C14.9737 17.1825 15.7383 15.0121 15.9137 12.75H19.2117C18.8951 15.8298 16.6507 18.3383 13.7053 19.0483ZM19.2117 11.25H15.9137C15.7383 8.98792 14.9737 6.81747 13.7052 4.95168C16.6507 5.66175 18.8951 8.17015 19.2117 11.25ZM3.25 12C3.25 7.16751 7.16751 3.25 12 3.25C16.8325 3.25 20.75 7.16751 20.75 12C20.75 16.8325 16.8325 20.75 12 20.75C7.16751 20.75 3.25 16.8325 3.25 12Z"
      fill={`url(#${gradientId})`}
    />
    <defs>
      <linearGradient
        id={gradientId}
        x1="-0.512498"
        y1="1.4125"
        x2="19.525"
        y2="24.95"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6E3CFF" />
        <stop offset="1" stopColor="#4BA5FF" />
      </linearGradient>
    </defs>
  </svg>
);
};
import logo_ru from 'assets/img/logo_ru.svg';
import clsx from 'clsx';
import { Button } from 'components/shared/Button';
import { LoginRuBGLeftIcon } from 'components/shared/Icons/LoginRuBGLeftIcon';
import { LoginRuBGRightIcon } from 'components/shared/Icons/LoginRuBGRightIcon';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { observer } from 'mobx-react-lite';
import { NavLink, useLocation } from 'react-router-dom';
import classes from './LoginRu.module.scss';
import { useTranslation } from 'react-i18next';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useEffect } from 'react';
import { serializeToURI } from 'utils';

export const LoginContainerRu = observer(() => {
  const { isSmallTablet } = useWindowWidth();
  const { t } = useTranslation();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const invite = queryParams.get('invite');

  const loginWithKokocID = (event: Event) => {
    event.preventDefault();

    const uriParams: {[k: string]: any} = {
      site_id: process.env.REACT_APP_KID_SITE_ID,
    }

    if (process.env.REACT_APP_KID_REDIRECT_URI) {
      uriParams.redirect_uri = process.env.REACT_APP_KID_REDIRECT_URI
    }

    const uriString: string = serializeToURI(uriParams)


    window.location.replace(
      `${process.env.REACT_APP_KID_URL}?${uriString}`
    );
  };

  useEffect(() => {
    const source = new URLSearchParams(location.search)?.get('utm_source');
    const existSource = localStorage.getItem('source_page');

    if (source && !existSource) {
      localStorage.setItem('source_page', source);
    }
  }, []);

  return (
    <>
      <TitleAndDescription pageName="Login" />

      <div className={classes.container}>
        <NavLink to={`${process.env.REACT_APP_MAIN_SITE_URL}`}>
          <img src={logo_ru} alt="" className={classes.logo} />
        </NavLink>

        <div className={classes.form}>
        {invite && (
            <div className={classes.invitation}>
              {t('Follow invitation link')}
            </div>
          )}
          <div className={classes.title} translate="no">
            {isSmallTablet ? (
              <>
                {t('Kokoc').toLocaleUpperCase()}
                <br />
                {t('Group platform').toLocaleUpperCase()}
              </>
            ) : (
              t('Kokoc Group platform').toLocaleUpperCase()
            )}
          </div>

          <div className={classes.description}>
            &mdash;{' '}
            {isSmallTablet ? (
              <>
                {t('comprehensive solutions for')}
                <br />
                {t('your business development')}
              </>
            ) : (
              t('comprehensive solutions for your business development')
            )}
          </div>

          <div
            onClick={() =>
              (window.location.href = `${process.env.REACT_APP_KID_URL}?site_id=${process.env.REACT_APP_KID_URL}`)
            }
            style={{ width: '10px', height: '10px', cursor: 'pointer' }}
          />

          <div className={classes.buttons}>
            <Button
              className={classes.button}
              theme="light"
              onClick={loginWithKokocID as any}
            >
              {t('Login with KokocID')}
            </Button>
            {
              !invite && (
                <Button
                  className={clsx(classes.button, classes['submit-button'])}
                  theme="primary-light"
                  onClick={loginWithKokocID as any}
                >
                  {t('Create account')}
                </Button>
              )
            }
          </div>
        </div>

        <div className={classes.right}>
          <LoginRuBGRightIcon />
        </div>

        <div className={classes.left}>
          <LoginRuBGLeftIcon />
        </div>
      </div>
    </>
  );
});
